import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import {
  DL_SIGNUP_URL,
  FAREWELL,
  LOGINURL,
  MAINTENANCE,
  NOTFOUNDURL,
  PASSWORD_RESET_URL,
  SMARTGOALSGENERATOR,
  EMBEDGENERATOR,
  TERMS,
} from 'src/app/app-routing.module';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/reducers';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'fp-app',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private primengConfig: PrimeNGConfig,
    private store: Store<AppState>,
    private utilsService: UtilsService,
    public authService: AuthenticationService
  ) {}
  currentUrl$ = this.store.select(state => state.router?.state?.url.split('?')[0]);
  loginUrl = LOGINURL;
  dlSignupUrl = DL_SIGNUP_URL;
  notFounUrl = NOTFOUNDURL;
  passwordResetUrl = PASSWORD_RESET_URL;
  farewell = FAREWELL;
  maintenanceUrl = MAINTENANCE;
  termsUrl = TERMS;
  smartGoalsGenerator = SMARTGOALSGENERATOR;
  embedGenerator = EMBEDGENERATOR;

  async ngOnInit() {
    this.primengConfig.ripple = true;
    const user = await this.authService.getMe();
    if (user) {
      this.utilsService.loadData2Store(user?.role);
    }
  }

  loginUrls = ['/' + LOGINURL, '/' + DL_SIGNUP_URL, '/' + PASSWORD_RESET_URL];
  noLayoutUrls = ['/' + NOTFOUNDURL, '/' + MAINTENANCE, '/' + TERMS, '/' + SMARTGOALSGENERATOR, '/' + EMBEDGENERATOR];
  farewellUrl = '/' + FAREWELL;

  isLoginLayout(url: string): boolean {
    return this.loginUrls.includes(url);
  }

  isNoLayout(url: string): boolean {
    return this.noLayoutUrls.includes(url);
  }

  isNoLayoutFull(url: string): boolean {
    return url === this.farewellUrl;
  }
}
