import { Field } from 'src/app/shared/types/field.types';
import * as FieldsActions from '../actions/fields.actions';

const initialState: Field[] = null;

export function reducer(state = initialState, action: FieldsActions.Actions): Field[] {
  switch (action.type) {
    case FieldsActions.FIELDS_LOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
