import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  GenderLoad,
  GenderLoadError,
  GenderLoadSuccess,
  GENDER_LOAD,
  GENDER_LOAD_ERROR,
  GENDER_LOAD_SUCCESS,
} from '../actions/gender.actions';
import { NotificationService } from '../../shared/services/notification.service';
import { GenderService } from 'src/app/shared/services/gender.service';

@Injectable()
export class GenderEffects {
  public genderLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GenderLoad>(GENDER_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(() =>
        this.genderService.getGender().pipe(
          map(gender => {
            return new GenderLoadSuccess(gender);
          }),
          catchError(() => of(new GenderLoadError()))
        )
      )
    )
  );

  public genderLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<GenderLoadSuccess>(GENDER_LOAD_SUCCESS),
        tap(() => (this.notificationService.loading = false))
      ),
    { dispatch: false }
  );

  public genderLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<GenderLoadError>(GENDER_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Gender not loaded'))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private genderService: GenderService
  ) {}
}
