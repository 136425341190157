import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { SessionService } from 'src/app/shared/services/session.service';
import { NotificationService } from '../../shared/services/notification.service';
import {
  SessionLoad,
  SessionLoadError,
  SessionLoadSuccess,
  SessionsLoad,
  SessionsLoadError,
  SessionsLoadSuccess,
  SESSIONS_LOAD,
  SESSIONS_LOAD_ERROR,
  SESSIONS_LOAD_SUCCESS,
  SessionUpdate,
  SessionUpdateSuccess,
  SESSION_LOAD,
  SESSION_LOAD_ERROR,
  SESSION_LOAD_SUCCESS,
  UPDATE_SESSION,
  UPDATE_SESSION_ERROR,
  UPDATE_SESSION_SUCCESS,
  SessionUpdateError,
} from '../actions/sessions.actions';
import { AppState } from '../reducers';
import { LoadingService } from 'src/app/shared/services/loading-service.service';

@Injectable()
export class SessionsEffects {
  public sessionsLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SessionsLoad>(SESSIONS_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.sessionService.getAllSessionsForRole(payload.role, payload.id).pipe(
          map(sessions => {
            return new SessionsLoadSuccess(sessions);
          }),
          catchError(() => of(new SessionsLoadError()))
        )
      )
    )
  );

  public sessionsLoadSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SessionsLoadSuccess>(SESSIONS_LOAD_SUCCESS),
        tap(payload => {
          this.notificationService.loading = false;
        })
      ),
    { dispatch: false }
  );

  public sessionsLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SessionsLoadError>(SESSIONS_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Sessions load failed'))
      ),
    { dispatch: false }
  );

  public sessionLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SessionLoad>(SESSION_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.sessionService.getSession(payload.id).pipe(
          map(session => {
            return new SessionLoadSuccess(session);
          }),
          catchError(() => of(new SessionLoadError()))
        )
      )
    )
  );

  public sessionLoadSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SessionLoadSuccess>(SESSION_LOAD_SUCCESS),
        tap(payload => {
          this.notificationService.loading = false;
        })
      ),
    { dispatch: false }
  );

  public sessionLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SessionLoadError>(SESSION_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Session load failed'))
      ),
    { dispatch: false }
  );

  public updateSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SessionUpdate>(UPDATE_SESSION),
      debounceTime(600),
      mergeMap(payload =>
        this.sessionService.updateSession(payload.id, payload.session).pipe(
          map(session => {
            return new SessionUpdateSuccess(session);
          }),
          catchError(() => of(new SessionUpdateError()))
        )
      )
    )
  );

  public updateSessionSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SessionUpdateSuccess>(UPDATE_SESSION_SUCCESS),
        withLatestFrom(this.store$.select(state => state.sessions)),
        tap(() => this.loadingService.setLoading(false))
      ),
    { dispatch: false }
  );

  public updateSessionError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SessionUpdateError>(UPDATE_SESSION_ERROR),
        tap(() => this.notificationService.openSnackBar('error', 'Session update failed')),
        tap(() => this.loadingService.setLoading(false))
      ),
    { dispatch: false }
  );

  constructor(
    private store$: Store<AppState>,
    private actions$: Actions,
    private loadingService: LoadingService,
    private notificationService: NotificationService,
    private sessionService: SessionService
  ) {}
}
