import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthenticationService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url === this.auth.loginUrl) {
      request = request.clone();
      return next.handle(request);
    } else {
      const token = this.auth.getToken();
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `JWT ${this.auth.getToken()}`,
          },
        });
      }
      return next.handle(request);
    }
  }
}
