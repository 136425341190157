import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/reducers';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { MenteeRelation } from 'src/app/shared/types/mentee-relations.types';
import { Observable } from 'rxjs';
import { SETTINGS } from 'src/app/app-routing.module';

@Component({
  selector: 'fp-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnChanges {
  mrs$: Observable<MenteeRelation[]> = this.store.select(state => state.menteeRelations);
  mobile_menu: MenuItem[];
  @Input() items: MenuItem[];
  @Input() user_role: string;
  @Output() hideSidebar = new EventEmitter<string>();

  constructor(public store: Store<AppState>, private router: Router, private authService: AuthenticationService) {
    this.router.events.subscribe(events => {
      if (events instanceof NavigationEnd) {
        this.hideSidebar.emit();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['items']) {
      if (this.items) {
        this.mobile_menu = [
          ...this.items,
          {
            label: 'Settings',
            routerLink: SETTINGS,
          },
        ];
      }
    }
  }

  signOut() {
    this.authService.signOut();
    this.router.navigate([`/auth/`]);
  }
}
