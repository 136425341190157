import { cloneDeep } from 'lodash';
import { Feedback } from 'src/app/shared/types/feedback.types';
import * as FeedbacksActions from '../actions/feedbacks.actions';

const initialState: Feedback[] = null;

export function reducer(state = initialState, action: FeedbacksActions.Actions): Feedback[] {
  let allFeedbacks: Feedback[];
  let ix: number;
  allFeedbacks = cloneDeep(state) ?? [];
  switch (action.type) {
    case FeedbacksActions.FEEDBACKS_SET_INITIAL:
      return initialState;
    case FeedbacksActions.FEEDBACKS_LOAD_SUCCESS:
      action.payload.forEach(feedback => {
        ix = allFeedbacks.findIndex(item => item.id === feedback.id);
        if (ix === -1) {
          allFeedbacks = [...allFeedbacks, feedback];
        } else {
          allFeedbacks[ix] = { ...allFeedbacks[ix], ...feedback };
        }
      });
      return allFeedbacks;
    case FeedbacksActions.FEEDBACK_LOAD_SUCCESS:
      ix = allFeedbacks.findIndex(item => item.id === action.payload.id);
      if (ix === -1) {
        allFeedbacks = [...allFeedbacks, action.payload];
      } else {
        allFeedbacks[ix] = { ...allFeedbacks[ix], ...action.payload };
      }
      return allFeedbacks;
    case FeedbacksActions.UPDATE_FEEDBACK_SUCCESS:
      ix = allFeedbacks.findIndex(item => item.id === action.payload.id);
      if (ix === -1) {
        allFeedbacks = [...allFeedbacks, action.payload];
      } else {
        allFeedbacks[ix] = action.payload;
      }
      return allFeedbacks;
    default:
      return state;
  }
}
