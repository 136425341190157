import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Gender } from '../types/gender.types';
@Injectable({
  providedIn: 'root',
})
export class GenderService {
  constructor(private http: HttpClient) {}

  public getGender() {
    return this.http.get<Gender[]>(environment.apiUrl + '/gender/');
  }
}
