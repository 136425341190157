import * as GenderActions from '../actions/gender.actions';
import { Gender } from 'src/app/shared/types/gender.types';

const initialState: Gender[] = null;

export function reducer(state = initialState, action: GenderActions.Actions): Gender[] {
  switch (action.type) {
    case GenderActions.GENDER_LOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
