import * as CitiesActions from '../actions/cities.actions';
import { City } from 'src/app/shared/types/city.types';

const initialState: City[] = null;

export function reducer(state = initialState, action: CitiesActions.Actions): City[] {
  switch (action.type) {
    case CitiesActions.CITIES_LOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
