<ng-container *ngIf="currentUrl$ | async">
  <ng-container *ngIf="isLoginLayout(currentUrl$ | async); else noLayouts" [ngTemplateOutlet]="loginLayout"></ng-container>

  <ng-template #noLayouts>
    <ng-container *ngIf="isNoLayoutFull(currentUrl$ | async); else otherLayouts" [ngTemplateOutlet]="noLayout"></ng-container>
  </ng-template>

  <ng-template #otherLayouts>
    <ng-container *ngIf="isNoLayout(currentUrl$ | async); else defaultLayout" [ngTemplateOutlet]="noLayout"></ng-container>
  </ng-template>

  <ng-template #defaultLayout>
    <ng-container [ngTemplateOutlet]="layout"></ng-container>
  </ng-template>
</ng-container>

<ng-template #loginLayout>
  <fp-layout-login>
    <router-outlet></router-outlet>
  </fp-layout-login>
</ng-template>
<ng-template #layout>
  <fp-layout>
    <router-outlet></router-outlet>
  </fp-layout>
</ng-template>
<ng-template #noLayout>
  <router-outlet></router-outlet>
</ng-template>
