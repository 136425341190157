import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import * as statusesActions from '../actions/statuses.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { StatusService } from 'src/app/shared/services/status.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Injectable()
export class StatusEffects {
  loadStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(statusesActions.StatusesLoad),
      tap(() => (this.notificationService.loading = true)),
      withLatestFrom(this.store.select(state => state.statuses)),
      mergeMap(([action, storeStatuses]) => {
        if (storeStatuses === null) {
          return this.statusService.getAllStatuses().pipe(
            map(statuses => statusesActions.StatusesLoadSuccess({ statuses })),
            catchError(() => of(statusesActions.StatusesLoadError()))
          );
        } else {
          return [];
        }
      })
    )
  );

  loadStatusesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(statusesActions.StatusesLoadSuccess),
        tap(() => (this.notificationService.loading = false))
      ),
    { dispatch: false }
  );

  loadStatusesError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(statusesActions.StatusesLoadError),
        tap(() => {
          this.notificationService.loading = false;
          this.notificationService.openSnackBar('error', 'Statuses not loaded');
        })
      ),
    { dispatch: false }
  );

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private notificationService: NotificationService,
    private statusService: StatusService
  ) {}
}
