import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { PersonalAttributesService } from 'src/app/shared/services/personal-attribute.service';
import { NotificationService } from '../../shared/services/notification.service';
import {
  PersonalAttributesLoad,
  PersonalAttributesLoadError,
  PersonalAttributesLoadSuccess,
  PERSONALATTRIBUTES_LOAD,
  PERSONALATTRIBUTES_LOAD_ERROR,
  PERSONALATTRIBUTES_LOAD_SUCCESS,
} from '../actions/personal-attributes.actions';
import { AppState } from '../reducers';

@Injectable()
export class PersonalAttributeEffects {
  public genderLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PersonalAttributesLoad>(PERSONALATTRIBUTES_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(() =>
        this.personalAttributesService.getPersonalAttributes().pipe(
          map(personalAttributes => {
            return new PersonalAttributesLoadSuccess(personalAttributes);
          }),
          catchError(() => of(new PersonalAttributesLoadError()))
        )
      )
    )
  );

  public personalAttributesLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalAttributesLoadSuccess>(PERSONALATTRIBUTES_LOAD_SUCCESS),
        tap(() => (this.notificationService.loading = false))
      ),
    { dispatch: false }
  );

  public personalAttributesLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalAttributesLoadError>(PERSONALATTRIBUTES_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'PersonalAttributes not loaded'))
      ),
    { dispatch: false }
  );

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private notificationService: NotificationService,
    private personalAttributesService: PersonalAttributesService
  ) {}
}
