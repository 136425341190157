import { AfterViewChecked, Component, Input, OnChanges } from '@angular/core';
import { faSquareEnvelope } from '@fortawesome/free-solid-svg-icons';
import { MenuItem } from 'primeng/api';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/reducers';
import { MenteeRelation } from 'src/app/shared/types/mentee-relations.types';
import { TERMS } from 'src/app/app-routing.module';

@Component({
  selector: 'fp-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class LeftMenuComponent implements AfterViewChecked, OnChanges {
  constructor(public store: Store<AppState>) {}
  faEnvelope = faSquareEnvelope;

  menteeRelations: MenteeRelation[];
  @Input() items: MenuItem[];
  @Input() user_role: string;
  smaller_font: boolean = false;
  TERMS = TERMS;

  ngOnChanges(changes) {
    if (this.user_role === 'CC') {
      this.smaller_font = true;
    } else {
      this.smaller_font = false;
    }
  }

  ngAfterViewChecked() {
    this.highlight();
  }

  highlight() {
    let elements = document.getElementsByClassName('p-menuitem-link-active');
    if (elements.length !== 0) {
      const parentWithClass = elements[0].closest('.p-submenu-list');
      let highlited = document.querySelectorAll('.p-panelmenu-header.orange-light-background');
      Array.from(highlited).forEach(function (element) {
        element.classList.remove('orange-light-background', 'font-bold');
      });
      if (parentWithClass) {
        let header = parentWithClass.closest('.p-panelmenu-panel').querySelector('.p-panelmenu-header');
        header.classList.add('orange-light-background', 'font-bold');
      }
    }
  }
}
