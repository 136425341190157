import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  CountriesLoad,
  CountriesLoadError,
  CountriesLoadSuccess,
  COUNTRIES_LOAD,
  COUNTRIES_LOAD_ERROR,
  COUNTRIES_LOAD_SUCCESS,
} from '../actions/countries.actions';
import { NotificationService } from '../../shared/services/notification.service';
import { CountryService } from 'src/app/shared/services/country.service';

@Injectable()
export class CountryEffects {
  public countriesLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CountriesLoad>(COUNTRIES_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(() =>
        this.countryService.getCountries().pipe(
          map(countries => {
            return new CountriesLoadSuccess(countries);
          }),
          catchError(() => of(new CountriesLoadError()))
        )
      )
    )
  );

  public countriesLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CountriesLoadSuccess>(COUNTRIES_LOAD_SUCCESS),
        tap(() => (this.notificationService.loading = false))
      ),
    { dispatch: false }
  );

  public countriesLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CountriesLoadError>(COUNTRIES_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Countries not loaded'))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private countryService: CountryService
  ) {}
}
