import { Component } from '@angular/core';

@Component({
  selector: 'fp-layout-login',
  templateUrl: './layout-login.component.html',
  styleUrls: ['./layout-login.component.scss'],
})
export class LayoutLoginComponent {
  constructor() {}
}
