import * as SettingActions from '../actions/settings.actions';
import { Settings } from 'src/app/shared/types/settings.types';

const initialState: Settings = { user: null };

export function reducer(state: Settings = initialState, action: SettingActions.Actions): Settings {
  switch (action.type) {
    case SettingActions.UPDATE_USER:
      return { ...state, user: action.user };
    case SettingActions.REMOVE_USER:
      localStorage.removeItem('token');
      return { ...state, user: null };
    default:
      return state;
  }
}
