import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { NotificationService } from '../../shared/services/notification.service';
import {
  CompanyLoad,
  CompanyLoadError,
  CompanyLoadSuccess,
  CompanyUpdate,
  CompanyUpdateSuccess,
  CompanyUpdateError,
  COMPANY_LOAD,
  COMPANY_LOAD_ERROR,
  COMPANY_LOAD_SUCCESS,
  UPDATE_COMPANY,
  UPDATE_COMPANY_ERROR,
  UPDATE_COMPANY_SUCCESS,
} from '../actions/company.actions';
import { AppState } from '../reducers';
import { CompanyService } from 'src/app/shared/services/company.service';
import { MenteeRelationLoad } from '../actions/mentee-relations.actions';

@Injectable()
export class CompanyEffects {
  public companyLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CompanyLoad>(COMPANY_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.companyService.getCompanyOfUser().pipe(
          map(company => {
            return new CompanyLoadSuccess(company);
          }),
          catchError(() => of(new CompanyLoadError()))
        )
      )
    )
  );

  public companyLoadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CompanyLoadSuccess>(COMPANY_LOAD_SUCCESS),
      tap(() => (this.notificationService.loading = false)),
      concatMap(val => [new MenteeRelationLoad('0', 'mentee')])
    )
  );

  public companyLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CompanyLoadError>(COMPANY_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Company load failed'))
      ),
    { dispatch: false }
  );

  public updateCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CompanyUpdate>(UPDATE_COMPANY),
      mergeMap(payload =>
        this.companyService.updateCompany(payload.id, payload.company).pipe(
          map(company => {
            return new CompanyUpdateSuccess(company);
          }),
          catchError(() => of(new CompanyUpdateError()))
        )
      )
    )
  );

  public updateCompanySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CompanyUpdateSuccess>(UPDATE_COMPANY_SUCCESS),
        withLatestFrom(this.store$.select(state => state.company))
      ),
    { dispatch: false }
  );

  public updateCompanyError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CompanyUpdateError>(UPDATE_COMPANY_ERROR),
        tap(() => this.notificationService.openSnackBar('error', 'Company update failed'))
      ),
    { dispatch: false }
  );

  constructor(
    private store$: Store<AppState>,
    private actions$: Actions,
    private notificationService: NotificationService,
    private companyService: CompanyService
  ) {}
}
