import { createReducer, on } from '@ngrx/store';
import * as statusesActions from '../actions/statuses.actions';
import { Status } from 'src/app/shared/types/status.types';

export const initialState: Status[] = null;

export const statusesReducer = createReducer(
  initialState,
  on(statusesActions.StatusesLoadSuccess, (state, { statuses }) => statuses)
);
