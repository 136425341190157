<div class="banner relative text-black" *ngIf="visibleBanner">
  <fp-head-banner [bannerType]="bannerType"></fp-head-banner>
  <p-button
    icon="pi pi-times"
    styleClass="p-button-rounded p-button-text absolute top-0 right-0"
    (onClick)="closeBanner()"
  ></p-button>
</div>
<div class="header" [class.vis-banner]="visibleBanner">
  <fp-header [user_role]="user_role"></fp-header>
</div>
<p-sidebar [(visible)]="visibleSidebar" [baseZIndex]="100" position="right">
  <fp-mobile-menu (hideSidebar)="hideSidebar()" [user_role]="user_role" [items]="items"></fp-mobile-menu>
</p-sidebar>
<div class="main-container flex justify-content-center">
  <div class="inner-container">
    <div class="flex relative">
      <div *ngIf="!visibleSidebar">
        <button
          id="panel-button"
          (click)="visibleSidebar = true"
          pButton
          pRipple
          type="button"
          icon="pi pi-bars"
          class="p-button-rounded p-button-text"
        ></button>
      </div>
      <div id="left-menu" [class.vis-banner]="visibleBanner">
        <fp-menu [items]="items" [user_role]="user_role"></fp-menu>
      </div>
      <div class="right-container" [class.vis-banner]="visibleBanner">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
