import { Mentor } from 'src/app/shared/types/mentor.types';
import * as MentorActions from '../actions/mentor.actions';

const initialState: Mentor = null;

export function reducer(state = initialState, action: MentorActions.Actions): Mentor {
  switch (action.type) {
    case MentorActions.MENTOR_SET_INITIAL:
      return initialState;
    case MentorActions.MENTOR_LOAD_SUCCESS:
      return action.payload;
    case MentorActions.UPDATE_MENTOR_SUCCESS:
      return action.mentor;
    default:
      return state;
  }
}
