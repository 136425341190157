import * as TeamSizesActions from '../actions/team-sizes.actions';
import { Value } from 'src/app/shared/types/value-type.types';

const initialState: Value[] = null;

export function reducer(state = initialState, action: TeamSizesActions.Actions): Value[] {
  switch (action.type) {
    case TeamSizesActions.TEAMSIZES_LOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
