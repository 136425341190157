import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../shared/shared.module';
import { FeedbacksEffects } from './effects/feedbacks.effects';
import { MenteeRelationsEffects } from './effects/mentee-relations.effects';
import { MenteeEffects } from './effects/mentee.effects';
import { SessionsEffects } from './effects/sessions.effects';
import { MentorEffects } from './effects/mentor.effects';
import { LanguageEffects } from './effects/languages.effects';
import { EnvironmentEffects } from './effects/environments.effects';
import { CountryEffects } from './effects/countries.effects';
import { CityEffects } from './effects/cities.effects';
import { TeamSizeEffects } from './effects/team-sizes.effects';
import { GenderEffects } from './effects/gender.effects';
import { FieldEffects } from './effects/fields.effects';
import { SkillEffects } from './effects/skills.effects';
import { PersonalAttributeEffects } from './effects/personal-attributes.effects';
import { CompanyEffects } from './effects/company.effects';
import { ConversationEffects } from './effects/conversation.effects';
import { CoachEffects } from './effects/coach.effects';
import { StatusEffects } from './effects/statuses.effects';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    EffectsModule.forFeature([CityEffects]),
    EffectsModule.forFeature([CoachEffects]),
    EffectsModule.forFeature([CompanyEffects]),
    EffectsModule.forFeature([ConversationEffects]),
    EffectsModule.forFeature([CountryEffects]),
    EffectsModule.forFeature([EnvironmentEffects]),
    EffectsModule.forFeature([FeedbacksEffects]),
    EffectsModule.forFeature([FieldEffects]),
    EffectsModule.forFeature([GenderEffects]),
    EffectsModule.forFeature([LanguageEffects]),
    EffectsModule.forFeature([MenteeEffects]),
    EffectsModule.forFeature([MenteeRelationsEffects]),
    EffectsModule.forFeature([MentorEffects]),
    EffectsModule.forFeature([PersonalAttributeEffects]),
    EffectsModule.forFeature([SessionsEffects]),
    EffectsModule.forFeature([SkillEffects]),
    EffectsModule.forFeature([StatusEffects]),
    EffectsModule.forFeature([TeamSizeEffects]),
  ],
})
export class StateModule {}
