import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Value } from '../types/value-type.types';

@Injectable({
  providedIn: 'root',
})
export class TeamSizeService {
  constructor(private http: HttpClient) {}

  /**
   * Query all team_sizes
   * @returns team_sizes
   */
  public getTeamSizes() {
    return this.http.get<Value[]>(environment.apiUrl + '/team_size/');
  }
}
