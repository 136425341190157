import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmationService, MessageService } from 'primeng/api';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppRoutingModule, routes } from './app-routing.module';
import { AppComponent } from './core/containers/app/app.component';
import { CoreModule } from './core/core.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StateModule } from './state/state.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { RouteService } from './shared/services/route.service';
import { AuthenticationService } from './shared/services/authentication.service';
import { reducers, metaReducers } from './state/reducers';
import { environment } from 'src/environments/environment';
import { PageNotFoundComponent } from './service-pages/page-not-found/page-not-found.component';
import { IframeRendererComponent } from './iframe-renderer/iframe-renderer.component';
import { GeneralInterceptor } from './shared/interceptors/general.interceptor';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [PageNotFoundComponent, IframeRendererComponent],
  imports: [
    StateModule,
    AppRoutingModule,
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, { useHash: false }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode(), connectInZone: true }),
  ],
  providers: [
    AuthenticationService,
    MessageService,
    ConfirmationService,
    RouteService,
    [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: GeneralInterceptor,
        multi: true,
      },
    ],
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
