import { cloneDeep } from 'lodash';
import { MenteeRelation } from 'src/app/shared/types/mentee-relations.types';
import * as MenteeRelationActions from '../actions/mentee-relations.actions';

const initialState: MenteeRelation[] = null;

export function reducer(state = initialState, action: MenteeRelationActions.Actions): MenteeRelation[] {
  let allMrs: MenteeRelation[];
  let ix: number;
  allMrs = cloneDeep(state) ?? [];
  switch (action.type) {
    case MenteeRelationActions.MENTEERELATION_SET_INITIAL:
      return initialState;
    case MenteeRelationActions.MENTEERELATION_LOAD_SUCCESS:
      return action.payload;
    case MenteeRelationActions.UPDATE_MENTEERELATION_SUCCESS:
      ix = allMrs.findIndex(item => item.id === action.payload.id);
      allMrs[ix] = { ...allMrs[ix], ...action.payload };
      return allMrs;
    default:
      return state;
  }
}
