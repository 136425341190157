import * as CountriesActions from '../actions/countries.actions';
import { Country } from 'src/app/shared/types/country.types';

const initialState: Country[] = null;

export function reducer(state = initialState, action: CountriesActions.Actions): Country[] {
  switch (action.type) {
    case CountriesActions.COUNTRIES_LOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
