import * as LanguagesActions from '../actions/languages.actions';
import { Language } from 'src/app/shared/types/language.types';

const initialState: Language[] = null;

export function reducer(state = initialState, action: LanguagesActions.Actions): Language[] {
  switch (action.type) {
    case LanguagesActions.LANGUAGES_LOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
