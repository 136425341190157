import { Skill } from 'src/app/shared/types/skill.types';
import * as SkillsActions from '../actions/skills.actions';

const initialState: Skill[] = null;

export function reducer(state = initialState, action: SkillsActions.Actions): Skill[] {
  switch (action.type) {
    case SkillsActions.SKILLS_LOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
