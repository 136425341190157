import { Component, Input } from '@angular/core';
import { REPORTS, TUTORIAL } from 'src/app/app-routing.module';
import { FINALRATING, PROGRESSRATING } from 'src/app/reports/reports.module';

@Component({
  selector: 'fp-head-banner',
  templateUrl: './head-banner.component.html',
  styleUrls: ['./head-banner.component.scss'],
})
export class HeadBannerComponent {
  FINALRATING = FINALRATING;
  PROGRESSRATING = PROGRESSRATING;
  REPORTS = REPORTS;
  TUTORIAL = TUTORIAL;
  @Input() bannerType;
}
