import { PersonalAttribute } from 'src/app/shared/types/personal-attribute.types';
import * as PersonalAttributesActions from '../actions/personal-attributes.actions';

const initialState: PersonalAttribute[] = null;

export function reducer(state = initialState, action: PersonalAttributesActions.Actions): PersonalAttribute[] {
  switch (action.type) {
    case PersonalAttributesActions.PERSONALATTRIBUTES_LOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
