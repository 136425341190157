import { Action } from '@ngrx/store';
import { Feedback } from 'src/app/shared/types/feedback.types';
import { AnyModelStr } from 'src/app/shared/types/model.types';

export const FEEDBACKS_SET_INITIAL = '[FEEDBACKS] Set initial';
export const FEEDBACKS_LOAD = '[FEEDBACKS] Load feedbacks';
export const FEEDBACKS_LOAD_SUCCESS = '[FEEDBACKS] Load feedbacks successful';
export const FEEDBACKS_LOAD_ERROR = '[FEEDBACKS] Load feedbacks failed';
export const FEEDBACK_LOAD = '[FEEDBACK] Load feedback';
export const FEEDBACK_LOAD_SUCCESS = '[FEEDBACK] Load feedback successful';
export const FEEDBACK_LOAD_ERROR = '[FEEDBACK] Load feedback failed';
export const UPDATE_FEEDBACKS = '[FEEDBACKS] Update feedbacks';
export const UPDATE_FEEDBACKS_SUCCESS = '[FEEDBACKS] Update feedbacks successful';
export const UPDATE_FEEDBACKS_ERROR = '[FEEDBACKS] Update feedbacks failed';
export const UPDATE_FEEDBACK = '[FEEDBACKS] Update feedback';
export const UPDATE_FEEDBACK_SUCCESS = '[FEEDBACKS] Update feedback successful';
export const UPDATE_FEEDBACK_ERROR = '[FEEDBACKS] Update feedback failed';

export class FeedbacksSetInitial implements Action {
  readonly type = FEEDBACKS_SET_INITIAL;
}
export class FeedbacksLoad implements Action {
  readonly type = FEEDBACKS_LOAD;
  constructor(public id: string, public role_type: AnyModelStr) {}
}

export class FeedbacksLoadSuccess implements Action {
  readonly type = FEEDBACKS_LOAD_SUCCESS;
  constructor(public payload: Feedback[]) {}
}

export class FeedbacksLoadError implements Action {
  readonly type = FEEDBACKS_LOAD_ERROR;
}
export class FeedbackLoad implements Action {
  readonly type = FEEDBACK_LOAD;
  constructor(public id: string) {}
}

export class FeedbackLoadSuccess implements Action {
  readonly type = FEEDBACK_LOAD_SUCCESS;
  constructor(public payload: Feedback) {}
}

export class FeedbackLoadError implements Action {
  readonly type = FEEDBACK_LOAD_ERROR;
}

export class FeedbackUpdate implements Action {
  readonly type = UPDATE_FEEDBACK;
  constructor(public feedback: Feedback) {}
}

export class FeedbackUpdateSuccess implements Action {
  readonly type = UPDATE_FEEDBACK_SUCCESS;
  constructor(public payload: Feedback) {}
}

export class FeedbackUpdateError implements Action {
  readonly type = UPDATE_FEEDBACK_ERROR;
}

export type Actions =
  | FeedbacksSetInitial
  | FeedbacksLoad
  | FeedbacksLoadSuccess
  | FeedbacksLoadError
  | FeedbackLoad
  | FeedbackLoadSuccess
  | FeedbackLoadError
  | FeedbackUpdate
  | FeedbackUpdateSuccess
  | FeedbackUpdateError;
