import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company } from '../types/company.types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpClient) {}
  public getCompanyOfUser() {
    return this.http.get<Company>(environment.apiUrl + `/companies/of_user/`);
  }

  public updateCompany(id: string, data: Partial<Company>) {
    return this.http.patch<Company>(environment.apiUrl + `/companies/${id}/`, data);
  }
}
