import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';

import { MenteeService } from 'src/app/shared/services/mentee.service';
import { NotificationService } from '../../shared/services/notification.service';

import { of } from 'rxjs';
import { Mentee } from 'src/app/shared/types/mentee.types';
import {
  MenteeLoad,
  MenteeLoadError,
  MenteeLoadSuccess,
  MENTEE_LOAD,
  MENTEE_LOAD_ERROR,
  MENTEE_LOAD_SUCCESS,
  UpdateMentee,
  UpdateMenteeError,
  UpdateMenteeSuccess,
  UPDATE_MENTEE,
  UPDATE_MENTEE_ERROR,
  UPDATE_MENTEE_SUCCESS,
} from '../actions/mentee.actions';
import { MenteeRelationLoad } from '../actions/mentee-relations.actions';
import { SessionsLoad, SessionsSetInitial } from '../actions/sessions.actions';
import { AppState } from '../reducers';
import { Store } from '@ngrx/store';
import { ConversationLoad } from '../actions/conversation.actions';

@Injectable()
export class MenteeEffects {
  public menteeLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MenteeLoad>(MENTEE_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(() =>
        this.menteeService.getMenteeOfUser().pipe(
          map(data => {
            const mentee: Mentee = data;
            return new MenteeLoadSuccess(mentee);
          }),
          catchError(() => of(new MenteeLoadError()))
        )
      )
    )
  );

  public menteeLoadSucces$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MenteeLoadSuccess>(MENTEE_LOAD_SUCCESS),
      tap(() => (this.notificationService.loading = false)),
      concatMap(val => [
        new MenteeRelationLoad(val.payload.id, 'mentee'),
        new SessionsSetInitial(),
        new SessionsLoad('mentee', val.payload.id),
        new ConversationLoad(),
      ])
    )
  );

  public menteeLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<MenteeLoadError>(MENTEE_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Loading mentee data failed'))
      ),
    { dispatch: false }
  );

  // Update mentee

  public updateMentee$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateMentee>(UPDATE_MENTEE),
      mergeMap(payload =>
        this.menteeService.updateMentee(payload.menteeId, payload.mentee).pipe(
          map(mentee => {
            return new UpdateMenteeSuccess(mentee);
          }),
          catchError(() => of(new UpdateMenteeError(payload.menteeId)))
        )
      )
    )
  );

  public updateMenteeSucces$ = createEffect(
    () => this.actions$.pipe(ofType<UpdateMenteeSuccess>(UPDATE_MENTEE_SUCCESS), withLatestFrom(this.store$.select(state => state.mentee))),
    { dispatch: false }
  );

  public updateMenteeError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateMenteeError>(UPDATE_MENTEE_ERROR),
        tap(() => this.notificationService.openSnackBar('error', 'Mentee update failed'))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private menteeService: MenteeService,
    private store$: Store<AppState>
  ) {}
}
