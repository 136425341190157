import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { HeaderComponent } from './components/header/header.component';
import { LayoutLoginComponent } from './components/layout-login/layout-login.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LeftMenuComponent } from './components/menu/menu.component';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
import { AppComponent } from './containers/app/app.component';
import { HeadBannerComponent } from './components/head-banner/head-banner.component';

@NgModule({
  imports: [SharedModule, RouterModule],

  declarations: [
    AppComponent,
    HeaderComponent,
    LayoutComponent,
    LeftMenuComponent,
    LayoutLoginComponent,
    MobileMenuComponent,
    HeadBannerComponent,
  ],
  exports: [AppComponent],
})
export class CoreModule {}
