import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MAINTENANCE } from 'src/app/app-routing.module';
import { AuthenticationService } from '../services/authentication.service';

export const retryCount = 0;
export const retryWaitMilliSeconds = 100;

@Injectable({
  providedIn: 'root',
})
export class GeneralInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthenticationService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry({ count: retryCount, delay: retryWaitMilliSeconds }),
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 0 && error.url.includes(environment.apiUrl)) {
            this.router.navigate([MAINTENANCE]);
            return throwError(() => new Error('FP: Unable to Connect to the Server:' + error.message));
          } else if ([401, 403].includes(error.status)) {
            // to sign out if token invalid
            this.authService.signOut();
            this.router.navigate([`/auth/`]);
          } else {
            return throwError(() => error);
          }
        }
      })
    );
  }
}
