import { Company } from 'src/app/shared/types/company.types';
import * as CompanyActions from '../actions/company.actions';

const initialState: Company = null;

export function reducer(state = initialState, action: CompanyActions.Actions): Company {
  switch (action.type) {
    case CompanyActions.COMPANY_SET_INITIAL:
      return initialState;
    case CompanyActions.COMPANY_LOAD:
      return state;
    case CompanyActions.COMPANY_LOAD_SUCCESS:
      return action.payload;
    case CompanyActions.UPDATE_COMPANY_SUCCESS:
      return action.company;
    default:
      return state;
  }
}
