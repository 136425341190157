import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { NotificationService } from '../../shared/services/notification.service';

import { of } from 'rxjs';
import {
  ConversationLoad,
  ConversationLoadError,
  ConversationLoadSuccess,
  CONVERSATION_LOAD,
  CONVERSATION_LOAD_ERROR,
  CONVERSATION_LOAD_SUCCESS,
  ADD_COMMENT_TO_CONVERSATION,
  ADD_COMMENT_TO_CONVERSATION_ERROR,
  ADD_COMMENT_TO_CONVERSATION_SUCCESS,
  AddCommentToConversation,
  AddCommentToConversationSuccess,
  AddCommentToConversationError,
  UPDATE_CONVERSATION,
  UpdateConversation,
  UPDATE_CONVERSATION_SUCCESS,
  UPDATE_CONVERSATION_ERROR,
  UpdateConversationError,
  UpdateConversationSuccess,
} from '../actions/conversation.actions';
import { Conversation } from 'src/app/shared/types/conversation.types';
import { ConversationService } from 'src/app/shared/services/conversation.service';
import { CommentService } from 'src/app/shared/services/comment.service';

@Injectable()
export class ConversationEffects {
  public conversationLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ConversationLoad>(CONVERSATION_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.conversationService.getConversations().pipe(
          map(data => {
            const conversations: Conversation[] = data;
            return new ConversationLoadSuccess(conversations);
          }),
          catchError(err => {
            console.log(err);
            return of(new ConversationLoadError());
          })
        )
      )
    )
  );

  public conversationLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ConversationLoadSuccess>(CONVERSATION_LOAD_SUCCESS),
        tap(() => (this.notificationService.loading = false))
      ),
    { dispatch: false }
  );

  public conversationLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ConversationLoadError>(CONVERSATION_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Conversation find failed'))
      ),
    { dispatch: false }
  );

  // addComment to conversation
  public addCommentToConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddCommentToConversation>(ADD_COMMENT_TO_CONVERSATION),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.commentService.addComment(payload.conversation, payload.comment).pipe(
          map(conversation => {
            return new AddCommentToConversationSuccess(conversation);
          }),
          catchError(() => of(new AddCommentToConversationError(payload.conversation.id)))
        )
      )
    )
  );

  public addCommentToConversationSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AddCommentToConversationSuccess>(ADD_COMMENT_TO_CONVERSATION_SUCCESS),
        tap(() => (this.notificationService.loading = false))
      ),
    { dispatch: false }
  );

  public addCommentToConversationError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AddCommentToConversationError>(ADD_COMMENT_TO_CONVERSATION_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Conversation update failed'))
      ),
    { dispatch: false }
  );

  // update conversation
  public updateConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateConversation>(UPDATE_CONVERSATION),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.conversationService.updateConversation(payload.conversationId, payload.conversation).pipe(
          map(conversation => {
            return new UpdateConversationSuccess(conversation);
          }),
          catchError(() => of(new UpdateConversationError()))
        )
      )
    )
  );

  public updateConversationSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateConversationSuccess>(UPDATE_CONVERSATION_SUCCESS),
        tap(() => (this.notificationService.loading = false))
      ),
    { dispatch: false }
  );

  public updateConversationError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateConversationError>(UPDATE_CONVERSATION_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Conversation updating failed'))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private conversationService: ConversationService,
    private commentService: CommentService
  ) {}
}
