import { cloneDeep } from 'lodash';
import { Session } from 'src/app/shared/types/session.types';
import * as SessionsActions from '../actions/sessions.actions';

const initialState: Session[] = null;

export function reducer(state = initialState, action: SessionsActions.Actions): Session[] {
  let allSessions: Session[];
  let ix: number;
  allSessions = cloneDeep(state) ?? [];
  switch (action.type) {
    case SessionsActions.SESSIONS_SET_INITIAL:
      return initialState;
    case SessionsActions.SESSIONS_LOAD_SUCCESS:
      action.payload.forEach(session => {
        ix = allSessions.findIndex(item => item.id === session.id);
        if (ix === -1) {
          allSessions = [...allSessions, session];
        } else {
          allSessions[ix] = { ...allSessions[ix], ...session };
        }
      });
      return allSessions;
    case SessionsActions.SESSION_LOAD_SUCCESS:
      ix = allSessions.findIndex(item => item.id === action.payload.id);
      if (ix === -1) {
        allSessions = [...allSessions, action.payload];
      } else {
        allSessions[ix] = { ...allSessions[ix], ...action.payload };
      }
      return allSessions;
    case SessionsActions.UPDATE_SESSION_SUCCESS:
      ix = allSessions.findIndex(item => item.id === action.payload.id);
      if (ix === -1) {
        allSessions = [...allSessions, action.payload];
      } else {
        allSessions[ix] = action.payload;
      }
      return allSessions;
    default:
      return state;
  }
}
