<div class="w-full p-2 pr-4 text-center orange-background">
  <p class="m-0 text-sm" [ngSwitch]="bannerType">
    <ng-container *ngSwitchCase="'offboarded'"
      >You have been offboarded from our mentor community. Within 30 days from offboarding you’ll lose access to this
      platform.</ng-container
    >
    <ng-container *ngSwitchCase="'offboardedCoach'"
      >You have been offboarded from our community. Within 30 days from offboarding you’ll lose access to this platform.</ng-container
    >
    <ng-container *ngSwitchCase="'notresposive'"
      >You have been marked as “unresponsive” by our Program Managers. Please contact us at mentoring&#64;femmepalette.com to talk about the
      next steps.</ng-container
    >
    <ng-container *ngSwitchCase="'tutorial'">
      Watch the Mentoring Platform platform tutorial <a [routerLink]="TUTORIAL" class="underline">here</a>.
    </ng-container>
    <ng-container *ngSwitchCase="'tutorial-coach'">
      Watch the Coaching Platform platform tutorial <a [routerLink]="TUTORIAL" class="underline">here</a>.
    </ng-container>
    <ng-container *ngSwitchCase="'mid_due'">
      First mid-program feedbacks are due. Start checking
      <a [routerLink]="REPORTS + '/' + PROGRESSRATING" class="underline">Mentee rating - Progress</a> to see the mentee’s feedback.
    </ng-container>

    <ng-container *ngSwitchCase="'final_due'">
      First final feedbacks are due. Start checking
      <a [routerLink]="REPORTS + '/' + FINALRATING" class="underline">Mentee rating - Final</a> to see the mentee’s feedback.
    </ng-container>
  </p>
</div>
