import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';

import { NotificationService } from '../../shared/services/notification.service';

import { of } from 'rxjs';
import {
  CoachLoad,
  CoachLoadError,
  CoachLoadSuccess,
  CoachUpdate,
  CoachUpdateError,
  CoachUpdateSuccess,
} from '../actions/coach.actions';
import { SessionsLoad, SessionsSetInitial } from '../actions/sessions.actions';
import { AppState } from '../reducers';
import { Store } from '@ngrx/store';
import { ConversationLoad } from '../actions/conversation.actions';
import { Coach } from 'src/app/shared/types/coach.types';
import { CoachService } from 'src/app/shared/services/coach.service';
import { MenteeRelationLoad } from '../actions/mentee-relations.actions';

@Injectable()
export class CoachEffects {
  public coachLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoachLoad),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(() =>
        this.coachService.getCoachOfUser().pipe(
          map((data: Coach) => {
            return CoachLoadSuccess({ coach: data });
          }),
          catchError(() => of(CoachLoadError()))
        )
      )
    )
  );

  public coachLoadSucces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoachLoadSuccess),
      tap(() => (this.notificationService.loading = false)),
      concatMap(val => [
        new MenteeRelationLoad(val.coach.id, 'coach'),
        new SessionsSetInitial(),
        new SessionsLoad('coach', val.coach.id),
        new ConversationLoad(),
      ])
    )
  );

  public coachLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoachLoadError),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Loading coach data failed'))
      ),
    { dispatch: false }
  );

  // Update coach
  public updateCoach$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoachUpdate),
      mergeMap(payload =>
        this.coachService.updateCoach(payload.coachId, payload.coach).pipe(
          map((data: Coach) => {
            return CoachUpdateSuccess({ coach: data });
          }),
          catchError(() => of(CoachUpdateError()))
        )
      )
    )
  );

  public updateCoachSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoachUpdateSuccess),
        tap(() => this.notificationService.openSnackBar('success', 'Your profile was successfully updated.')),
        withLatestFrom(this.store$.select(state => state.coach))
      ),
    { dispatch: false }
  );

  public updateCoachError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoachUpdateError),
        tap(() => this.notificationService.openSnackBar('error', 'Coach update failed'))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private coachService: CoachService,
    private store$: Store<AppState>
  ) {}
}
