import { Environment } from 'src/app/shared/types/environment.types';
import * as EnvironmentsActions from '../actions/environments.actions';

const initialState: Environment[] = null;

export function reducer(state = initialState, action: EnvironmentsActions.Actions): Environment[] {
  switch (action.type) {
    case EnvironmentsActions.ENVIRONMENTS_LOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
