import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Status } from '../types/status.types';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class StatusService {
  constructor(private http: HttpClient) {}

  /**
   * Returns statuses
   * @returns statuses
   */
  public getAllStatuses() {
    return this.http.get<Status[]>(environment.apiUrl + '/status/all/');
  }
}
